import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  const handle_submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const username = data.get('username');
    if (!username) return alert('username is required');

    const password = data.get('password');
    if (!password) return alert('password is required');

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/agent/login`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: data.get('username'),
            password: data.get('password'),
          }),
          credentials: 'include',
        }
      );

      const res = await response.json();
      if (!response.ok) throw new Error(res.message);
      navigate('/dashboard/prediksi');
    } catch (error: any) {
      alert(error.message);
    }
  };

  return (
    <>
      <div className='h-full bg-white hidden'></div>

      <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
          <img
            className='mx-auto h-32 w-auto rounded'
            src={require('../assets/images/logo.jpg')}
            alt='Auto Prediksi v2'
          />
        </div>

        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
          <form className='space-y-2' onSubmit={handle_submit}>
            <div>
              <div className='mt-2'>
                <input
                  id='username'
                  name='username'
                  type='username'
                  autoComplete='username'
                  required
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-chestbg-chestnut-rose-600 sm:text-sm sm:leading-6 text-center'
                  placeholder='username'
                />
              </div>
            </div>

            <div>
              <div className='mt-2'>
                <input
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='current-password'
                  required
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-chestbg-chestnut-rose-600 sm:text-sm sm:leading-6 text-center'
                  placeholder='password'
                />
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='flex w-full justify-center rounded-md bg-chestnut-rose-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-chestnut-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chestbg-chestnut-rose-600'
              >
                login
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
