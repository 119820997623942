const pasaran_2ad = [
  'Albania',
  'Argentina',
  'Berlin',
  'Hawaii',
  'Hokkaido',
  'Maroco',
  'Russia',
  'Saigon',
  'Slovenia',
  'SriLanka',
];

export { pasaran_2ad };
